(function ($w) {
	var feedbackTimeout = null;
	var feedbackTime = 5000;
	var isMobile = false;
	var data = {
		name: '',
		email: '',
		phone: '',
		newsletter: '',
		email_to: '',
		message: ''
	};

	var $form = $('.form-contact');
	var $submitButton = $form.find("button");
	var $feedback = $form.find(".feedback");
	if ($form.length) run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$form.submit(sendMessage);
	}

	function sendMessage(e) {
		e.preventDefault();
		setIsMobile();
		collectData();
		processData(e);
	}

	function setIsMobile() {
		isMobile = $w.width() < 768 ? true : false;
	}

	function collectData() {
		var name = '';
		var email = '';
		var phone = '';
		name = $form.find('#name').val();
		email = $form.find('#email').val();
		phone = $form.find('#phone').val();
		email_to = $form.find('.contact_email_to').val();
		newsletter = $form.find('#newsletter:checked');
		newsletter = newsletter.length;
		message = $form.find('#message').val();

		data.name = name;
		data.email = email;
		data.phone = phone;
		data.newsletter = newsletter;
		data.email_to = email_to;
		data.message = message;
		//console.log(data);
	}

	function processData(e) {
		$feedback.text($form.find('.contact_reply_wait').val());

		e.preventDefault();
		disableSubmit();
		$.ajax({
			url: 'ajax-contact.php',
			method: 'POST',
			data: data,
			error: function (msg) {
				showFeedback(msg);
			},
			success: function (msg) {
				showFeedback(msg);
			}
		});
	}

	function disableSubmit() {
		//$submitButton.attr('disabled', 'disabled');
	}

	function enableSubmit() {
		$submitButton.removeAttr('disabled');
	}

	function clearFields() {
		$form.find('#name').val('');
		$form.find('#email').val('');
		$form.find('#phone').val('');
		$form.find('#message').val('');

	}


	function showFeedback(msg) {
		if (msg.length>5) {
			msg=(msg.substr(-1));
		}
		
		var msg = msg.trim();
		//console.log(msg);
		//msg = 100;

		if(feedbackTimeout) clearTimeout(feedbackTimeout);

		$feedback.html($form.find('.contact_reply_'+msg).val());

		if (msg == 1) { // Success
			feedbackTimeout = setTimeout(function () {
				$feedback.text('');
				clearFields();
				enableSubmit();
			}, feedbackTime);
		} else {
			enableSubmit();
		}
	}

})($(window));