(function ($w) {
	var debounceTime = 130, debounceTimeout = null;
	var $artistLinks = $(".artist-link");
	var $artistTabs = $(".artist-tab");

	if ($artistLinks.length > 0 || $artistTabs.length > 0) run();

	function run() {
	 attachEvents();
	}

	function attachEvents() {
		//$('a').click(linkClicked);
		$artistLinks.click(artistTabClicked);
		$artistTabs.click(artistTabClicked);

		$w.resize(function () {
      debounce(checkHeightOnResize);
    });
    checkHeightOnResize();

   	tabClicked($(".artist-tabs-bar .artist-tab").first().attr('data-tab'))
	}

	function artistTabClicked(e) {
		var tab = $(this).attr('data-tab');
		tabClicked(tab);
	}

	function tabClicked(tab) {
		
		$(".artist-link").removeClass("active");
		$(".artist-tab").removeClass("active");
		$(".artist-link[data-tab='" + tab + "']").addClass("active");
		$(".artist-tab[data-tab='" + tab + "']").addClass("active");
		$(".artist-tab-wrapper").removeClass("active");
		$("." + tab + "-wrapper").addClass("active");
		//exhibitions.checkHeightOnResize();
		//publications.checkHeightOnResize();
		$(window).trigger('resize');
	}


	function isMobile() {
		if ($w.width() < 768) {
			return true;
		}	else {
			return false;
		}
	}

	function checkHeightOnResize() {

	}

	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }
 
})($(window)); 