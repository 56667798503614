var sliders = (function ($w) {
//letitia
  var slideWrapper = $(".main-slider"),
    iframes = slideWrapper.find('.embed-player'),
    lazyImages = slideWrapper.find('.slide-image'),
    lazyCounter = 0,
    slideSlick;
  var debounceTime = 50, debounceTimeout = null;

  if (slideWrapper.length) run();

  function run() {
    var autoplay = true;
    var firstSlideType = slideWrapper.find(".item:first").attr("class").split(" ")[1];
    if (firstSlideType == "video" || firstSlideType == "youtube" || firstSlideType == "vimeo") autoplay = false;
    //console.log(autoplay);

    slideWrapper.on("init", function(slick){
      slick = $(slick.currentTarget);
      var type = slick.find(".slick-current").attr("class").split(" ")[1];
      setTimeout(function(){
       // console.log(type);
        if (type == "video" || type == "youtube" || type == "vimeo") {
          slideWrapper.slick("slickPause");
        }
        //playPauseVideo(slick,"play");
        //playPauseVideo(slick,"pause");
      }, 1000);
      resizePlayer();
    });
    slideWrapper.on("beforeChange", function(event, slick) {
      slick = $(slick.$slider);
      playPauseVideo(slick,"pause");
    });
    slideWrapper.on("afterChange", function(event, slick) {
      slick = $(slick.$slider);
      var type = slick.find(".slick-current").attr("class").split(" ")[1];
      if (type == "video" || type == "youtube" || type == "vimeo") {
        slideWrapper.slick("slickPause");
      } else {
        slideWrapper.slick("slickPlay");
      }
      //playPauseVideo(slick,"play");
      playPauseVideo(slick,"pause");
    });
    slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
      lazyCounter++;
      if (lazyCounter === lazyImages.length){
        lazyImages.addClass('show');
        slideWrapper.slick("slickPlay");
      }
    });

    var asNavFor="", asNavForThumbs ="";
    if (slideWrapper.hasClass("with-thumbs")) {
      asNavFor = '.slider-thumbs';
      asNavForThumbs = slideWrapper;
     // lazyLoad = "progressive";
      var _first = slideWrapper.find(".item").first();
      if (_first.hasClass("youtube") || _first.hasClass("vimeo") || _first.hasClass("video")) autoplay = false;
    }

    //start the slider
    slideSlick = slideWrapper.slick({
      /*fade:true,*/
      autoplay: autoplay,
      autoplaySpeed:3000,
      lazyLoad:"progressive",
      speed:600,
      arrows:false,
      dots:true,
      asNavFor: asNavFor,
      cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)"
    });
    if (slideWrapper.hasClass("with-thumbs")) {
      $('.slider-thumbs').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: false,
        asNavFor: asNavForThumbs,
        arrows: true,
        infinite: false,
        focusOnSelect: true,
        cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)",
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      });
    }

   

    $w.on('resize orientationchange', function () {
      debounce(resizePlayer);
    });
    resizePlayer();

  }

  // POST commands to YouTube or Vimeo API
  function postMessageToPlayer(player, command){
    if (player == null || command == null) return;
    player.contentWindow.postMessage(JSON.stringify(command), "*");
  }


 // When the slide is changing
  function playPauseVideo(slick, control){
    var currentSlide, slideType, startTime, player, video;

    currentSlide = slick.find(".slick-current");
    slideType = currentSlide.attr("class").split(" ")[1];
    player = currentSlide.find("iframe").get(0);
    startTime = currentSlide.data("video-start");

    if (slideType === "vimeo") {
      switch (control) {
        case "play":
          if ((startTime != null && startTime > 0 ) && !currentSlide.hasClass('started')) {
            currentSlide.addClass('started');
            postMessageToPlayer(player, {
              "method": "setCurrentTime",
              "value" : startTime
            });
          }
          postMessageToPlayer(player, {
            "method": "play",
            "value" : 1
          });
          break;
        case "pause":
          postMessageToPlayer(player, {
            "method": "pause",
            "value": 1
          });
          break;
      }
    } else if (slideType === "youtube") {
      switch (control) {
        case "play":
         /* postMessageToPlayer(player, {
            "event": "command",
            "func": "mute"
          });*/
          postMessageToPlayer(player, {
            "event": "command",
            "func": "playVideo"
          });
          break;
        case "pause":
          postMessageToPlayer(player, {
            "event": "command",
            "func": "pauseVideo"
          });
          break;
      }
    } else if (slideType === "video") {
      video = currentSlide.children("video").get(0);
      if (video != null) {
        if (control === "play"){
          video.play();
          currentSlide.children("video").bind('ended',function(){
            //console.log('Video has ended!');
            slideWrapper.slick("slickNext");
          });
        } else {
          video.pause();
        }
      }
    }
  }
  function isMobile() {
    //console.log($w.width());
    if ($w.width() < 768) {
      //console.log("isMobile");
      return true;
    } else {
    //  console.log("NotMobile");
      return false;
    }
  }

  // Resize player
  function resizePlayer() {
    //console.log("resizePlayer");
    slideWrapper.css("width","10px");
    $(".slider-thumbs").width(10);
    var _width;// = slideWrapper.parent().width();
    var _height;// = slideWrapper.parent().height();
    var _m = "";
    if (isMobile()) _m = "-mobile";

    if (slideWrapper.data("width"+_m) == "parent") _width = slideWrapper.parent().width();
    else _width = slideWrapper.data("width"+_m);
  
    if (slideWrapper.data("height"+_m) == "parent") _height = slideWrapper.parent().height();
    else if (slideWrapper.data("height"+_m) == "width") _height = _width;
    else _height = slideWrapper.data("height"+_m);

    if (slideWrapper.hasClass("with-thumbs")) {
     //console.log(_width);
      _height = Math.floor(_width*0.6);
      //console.log(_height);
      slideWrapper.parent().height(_height);
    }
    if (slideWrapper.hasClass("top-slider")) {
     //console.log(_width);
      _height = Math.floor(_width*0.5);
      //console.log(_height);
      slideWrapper.parent().height(_height);
    }
   
   // console.log(slideWrapper.data("height"+_m));
    
    //console.log(_width);
    $(".slider-thumbs").width(_width);

    slideWrapper.width(_width);
    slideWrapper.height(_height);
    $(".item").width(_width);
    $(".item").height(_height);
    $(".slick-list").width(_width);
    $(".slick-list").height(_height);
    $("video").width(_width);
    $("video").height(_height);

    try { slideWrapper.slick("setPosition");
    } catch(error) {  }


    /*var c=0;
    //console.log($(".main-slider").slick('setPosition'));
    $(".slick-slide").each(function(){
      if ($(this).hasClass("slideWrapper-current")) {
       // slideWrapper.find(".slick-track").css("transform","translate3d(" + (-_width*c) + "px, 0px, 0px)");
        //console.log(c);
      }
      c++;
    });*/

    iframes.each(function(){
      var current = $(this);
        current.width(_width).height(_height).css({
        left: 0,
        top: 0
      });

    });

  }


  function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }
 
})($(window));
 

