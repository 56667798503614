(function ($w) {
	var timeOut, timeToGoToHref=800;

	run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		//$(".top-bar").addClass("full")
		$(".transition-white").addClass("hide");
		setTimeout(function(){ $(".top-bar").addClass("full"); $(".transition-white").css("height",0); },300);
		$('a').click(linkClicked);
	}

	function linkClicked(e) {
		var href = $(this).attr('href');
		var target = $(this).attr("target");
		if (href && href.substr(0, 6).toLowerCase() == "mailto") target="_blank";
		var fancybox = $(this).attr("data-fancybox");
		if (fancybox) return;
		e.preventDefault();
		if (!target) target = "_self" ;
		if (target) target = target.toLowerCase();
		var base =  document.getElementsByTagName('base')[0].href;
		var base_test = base.replace("http:", "");

		if (!href || href == "#") return;

		if (timeOut) clearTimeout(timeOut);

		if (target == "_blank") {
			window.open(href);
			return;
		}
		//console.log(href + " / " + target);
		$(".top-bar").removeClass("hide");
		$(".transition-white").css("height","100%");
		setTimeout(function(){ $(".transition-white").removeClass("hide"); },600);
	
		$(".transition-logo").addClass("show");

		timeOut = setTimeout(function(){
			if (href.includes(base) || href.includes(base_test)) {
				window.location.href = href;
			} else {
				window.location.href = base+href;
			}
		},timeToGoToHref);
	}


})($(window));