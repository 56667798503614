(function ($w) {
	var $element = $('.only-numbers');
	var $previousValue=$element.val();
	var $allowedCharCodes = [44,46,48,49,50,51,52,53,54,55,56,57];
	run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$element.keypress(validate);
	}

	function validate(e) {
		keyCode = e.charCode;
		str = $(this).val();
		//console.log("keyCode = " + keyCode)
		var $pos = $allowedCharCodes.indexOf(keyCode);
		if ($pos < 0) {
			return false;
		}

		return true;
	}
})($(window));