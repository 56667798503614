(function ($w) {
	var debounceTime = 50, debounceTimeout = null;
	var $tableLinks = $(".table-links")
	
	if ($tableLinks.length) run();

	function run() {
	 $w.resize(function () {
      debounce(checkOnResize);
    });
	 checkOnResize();
	}

	function checkOnResize() {
		if ($tableLinks.find(".date").length) {
			if ($tableLinks.width()<460) {
				$tableLinks.find(".date").addClass("hide");
				$tableLinks.find(".date.head").addClass("show");
				$(".description").find(".date.head").addClass("show");
				$tableLinks.addClass("mt");
			} else {
				$tableLinks.find(".date").removeClass("hide");
				$(".description").find(".date.head").removeClass("show");
				$tableLinks.removeClass("mt");
			}
		} 

		if ($tableLinks.find(".row-1").length) {
			$tableLinks.find(".sub-col").removeClass("smaller");
			$tableLinks.find(".row-1").height("auto");
			$tableLinks.height($tableLinks.find(".row-1").height());
			$tableLinks.find(".sub-col").removeClass("smaller");
			

			if ($tableLinks.find(".row-1").height() > 40) {
				$tableLinks.find(".sub-col").addClass("smaller");
				$tableLinks.height($tableLinks.find(".row-1").height());
			} else {
				$tableLinks.find(".sub-col").removeClass("smaller");
				$tableLinks.height($tableLinks.find(".row-1").height());
			}
		}

		if ($tableLinks.find(".row-2").length) {
			$tableLinks.find(".sub-col").removeClass("smaller");
			$tableLinks.find(".row-2").height("auto");
			$tableLinks.find(".sub-col").removeClass("smaller");

			$tableLinks.height($tableLinks.find(".row-1").height()+1+$tableLinks.find(".row-2").height());
			//console.log($tableLinks.find(".row-2").height());
			if ($tableLinks.find(".row-2").height() > 40) {
				$tableLinks.find(".sub-col").addClass("smaller");
				$tableLinks.height($tableLinks.find(".row-1").height()+1+$tableLinks.find(".row-2").height());
			} else {
				$tableLinks.find(".sub-col").removeClass("smaller");
				$tableLinks.height($tableLinks.find(".row-1").height()+1+$tableLinks.find(".row-2").height());
			}
		}

		
	}

	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
    setTimeout(fn, debounceTime+50);
  }


})($(window));