(function ($w) {
	var debounceTime = 130, debounceTimeout = null;
	var $press = $(".press");

	if ($press.length > 0) run();

	function run() {
	 attachEvents();
	}

	function attachEvents() {
	//	console.log("press");
		$w.resize(function () {
      debounce(checkHeightOnResize);
    });
    checkHeightOnResize();
    setTimeout(checkHeightOnResize, 100);
	}

	function isMobile() {
		if ($w.width() < 768) {
			return true;
		}	else {
			return false;
		}
	}

	function checkHeightOnResize() {

		var thumbWidth;
		var maxHeight;

		$.each( $(".press-listing a.pr"), function( i, l ){
			var $thumb = $(this);
			$thumb.css("height","auto");
			if (!thumbWidth) thumbWidth = $thumb.width();
			//$thumb.width(thumbWidth);
			$thumb.find(".img-wrapper").height(thumbWidth/2);
			if (!maxHeight || maxHeight < $thumb.height()) maxHeight = $thumb.height();
		});
		
		//console.log(maxHeight);

		$.each( $(".press-listing a.pr"), function( i, l ){
			var $thumb = $(this);
			$thumb.height(maxHeight);
		});

	}

	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }
 
})($(window)); 