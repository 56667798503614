(function ($w) {
	var clickDisabled = false;
	var debounceTime = 50, debounceTimeout = null;
	var $main = $("main"), $asideWrapper = $("aside").find(".aside-wrapper"), $menu = $(".menu"), $body = $("body"), $menuButton = $('.menu-button'),$menuLinks = $('.menu-links'), $content = $('.content'), $footer = $('footer');
	run();

	function run() {
	 attachEvents();
	}

	function attachEvents() {
		$menuButton.click(toggleMenu);
		$w.resize(function () {
      debounce(checkHeightOnResize);
    });
    checkHeightOnResize();
	}

	function isMobile() {
		//console.log($w.width());
		if ($w.width() < 768) {
			//console.log("isMobile");
			return true;
		}	else {
		//	console.log("NotMobile");
			return false;
		}
	}

	function toggleMenu() {
		if (!clickDisabled) {
			if ($menu.hasClass('menu-open')) closeMenu();
			else openMenu();
			disableClick(300);
		}
	}

	function disableClick(_time) {
		clickDisabled = true;
		setTimeout(function() {clickDisabled = false; }, _time);
	}


	function closeMenu() {
		$content.removeClass("fade");
		$footer.removeClass("fade");
		$content.off("click"); $footer.off("click");
		$menu.removeClass('menu-open');
	}

	function openMenu() {
		$content.addClass('fade');
		$footer.addClass('fade');
		$menu.addClass('menu-open');
		checkHeightOnResize();
		$content.click(closeMenu);$footer.click(closeMenu);
	}

	function checkHeightOnResize() {
		var _max = 125;
		//console.log((parseInt($main.css("min-height"))+145+70));
		//console.log($asideWrapper.height()+14+70);
		if ($w.height() < (parseInt($asideWrapper.height())+145+70)) {
			$body.addClass("minheight");
		} else {
			$body.removeClass("minheight");
		}

		if (isMobile()) {
			$menuLinks.css("max-height", ($w.height() - _max)+"px");
			$menuLinks.css("overflow-y", "auto");
		} else {
			$menuLinks.css("max-height", "10000px");
			$menuLinks.css("overflow", "hidden");
		}
	}

	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }
 
})($(window));