var publications = (function ($w) {
	var debounceTime = 130, debounceTimeout = null;
	var $publication = $(".publication");
	var $overview = $(".publications").find(".overview");
	var $main = $("main");

	if ($publication.length > 0) run();

	function run() {
	 attachEvents();
	}

	function attachEvents() {
		$w.resize(function () {
      debounce(checkHeightOnResize);
    });
    checkHeightOnResize();
    setTimeout(checkHeightOnResize, 100);
	}

	function isMobile() {
		if ($w.width() < 768) {
			return true;
		}	else {
			return false;
		}
	}

	function checkHeightOnResize() {
			var squareHeight = 0;
			$.each( $publication, function( i, l ){
				if (!squareHeight) squareHeight = $(this).width();
				$(this).height(squareHeight);
			});

		main.checkContactButton();
	}

	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }

  return {
		checkHeightOnResize: checkHeightOnResize
	};
 
})($(window)); 