(function ($w) {
	var feedbackTimeout = null;
	var feedbackTime = 5000;
	var isMobile = false;
	var data = {
		fullName: '',
		email: '',
		phoneNumber: ''
	};

	var $form = $('.form-newsletter');
	var $submitButton = $form.find("button");
	var $name = $form.find(".name");
	var $email = $form.find(".email");
	var $phone = $form.find(".phone");
	var $submitButton = $form.find("button");
	var $feedback = $(".newsletter-feedback");
	
	var $contactWrapper = $(".contact-newsletter-wrapper");
	if ($contactWrapper.length) run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$form.submit(subscribeToNewsletter);
		$contactWrapper.click(wrapperClicked);
		//showForm();
	}
	function wrapperClicked(e) {
		//console.log("clicked");
		if (!$contactWrapper.hasClass("open")) {
			showForm();
		} 

	}
	function showForm() {
		$contactWrapper.off("click");
		$contactWrapper.addClass("open");
		$contactWrapper.find(".title").addClass("hide");
		setTimeout(function() { $contactWrapper.find(".title").addClass("none"); $contactWrapper.find("form").addClass("show") } , 200);
	}
	function hideForm() {
		$contactWrapper.click(wrapperClicked);
		$contactWrapper.find("form").removeClass("show");
 		$contactWrapper.removeClass("open");
 		$contactWrapper.find(".title").removeClass("none")
		setTimeout(function() { $contactWrapper.find(".title").removeClass("hide") } , 200);
	}


	function subscribeToNewsletter(e) {
		e.preventDefault();
		if ($submitButton.hasClass("disabled")) return;
		collectData();
		processData(e);
	}

	function collectData() {
		data.fullName = $name.val();
		data.email = $email.val();
		data.phoneNumber = $phone.val();
		//console.log(data);
	}

	function processData(e) {
		$feedback.text($form.find('.newsletter_reply_wait').val());
		disableSubmit();
		$.ajax({
			url: 'ajax-newsletter.php',
			method: 'POST',
			data: data,
			error: function (msg) {
				showFeedback(msg);
			},
			success: function (msg) {
				showFeedback(msg);
			}
		});
	}

	function disableSubmit() {
		$submitButton.addClass('disabled');
	}

	function enableSubmit() {
		$submitButton.removeClass('disabled');
	}

	function clearFields() {
		$name.val('');
		$email.val('');
		$phone.val('');
	}


	function showFeedback(msg) {
		if (msg.length>5) {
			msg=(msg.substr(-1));
		}
		//console.log(data);
		//console.log(msg);

		var msg = msg.trim();

		console.log(msg);
		//msg = 100;

		if(feedbackTimeout) clearTimeout(feedbackTimeout);

		$feedback.text($form.find('.newsletter_reply_'+msg).val());

		if (msg == 100) { // Success
			feedbackTimeout = setTimeout(function () {
				$feedback.text('');
				clearFields();
				enableSubmit();
				hideForm();
			}, feedbackTime);
		} else {
			enableSubmit();
		}
	}

})($(window));