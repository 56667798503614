var googleMap = (function () {
	var map = null;
	var $map = $('#map');
	var googleMapStyles = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fbee3c"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#c2c4c4"
            },
            {
                "visibility": "on"
            }
        ]
    }
	];

	function init() {
		//console.log($map.attr('data-zoom'));
   map = new google.maps.Map(document.getElementById('map'), {
      zoom: +$map.attr('data-zoom'),
      center: { lat: +$map.attr('data-lat'), lng: +$map.attr('data-lng') },
      styles: googleMapStyles
    });
	}


	function addMarker(area) {
		var marker = new google.maps.Marker({
          position: area.position,
          map: map,
          icon: 'assets/images/pin.png',
        });
	}

	return {
		init: init,
		addMarker: addMarker
	};

})();