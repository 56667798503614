var main = (function ($w) {
	var debounceTime = 120, debounceTimeout = null;
	var debounceTime_2 = 120, debounceTimeout_2 = null;
	var $main = $("main");
	var $aside = $("aside");
	var $asideWrapper = $("aside").find(".aside-wrapper");
	var $headerLink = $(".header-link");
	var $body = $('body');
	var mainHeight, windowHeight,scrollTop,asideWrapperHeight,marginTopAside, distancewindowbody;
	var footerHeight = $("footer").height();
	var headerHeight = $("header").height();
	var headerFooterHeight = $("header").height() + $("footer").height();

	run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$w.resize(function () {
     debounce(checkDimensions);
    });
    $w.scroll(function () {	
      debounce_2(checkHeaderPosition);
    });
    checkDimensions();
    setTimeout(checkDimensions, 50);
	}

	function checkDimensions() {
		checkHeaderPosition();
		checkMainHeight();
		//setTimeout(checkMainHeight, 50);
		checkHeaderPosition();

		if (isMobile()) {
			//console.log("remove sticky");
			$body.removeClass("sticky");
		}

	}
	function isMobile() {
		//console.log($w.width());
		if ($w.width() < 768) {
			//console.log("isMobile");
			return true;
		}	else {
		//	console.log("NotMobile");
			return false;
		}
	}

	function checkMainHeight() {
		scrollTop = $w.scrollTop();
		//console.log($asideWrapper.height());

		asideWrapperHeight = $asideWrapper.height();
		//console.log(asideWrapperHeight);
		windowHeight = $(window).height();
		scrollTop = $w.scrollTop();
		footerHeight = $("footer").height();

		if ($body.hasClass("sticky")) headerHeight = 80;
		else headerHeight = 150;
		headerHeight = 150;
		if (isMobile()) headerHeight = 125;
		$main.find(".exhibition-1").height(10);
		$main.find(".exhibition-2").height(10);

//		console.log(windowHeight + " / " + footerHeight + " / " + headerHeight);
//		console.log(windowHeight - footerHeight - headerHeight);
		mainHeight = windowHeight - footerHeight - headerHeight;

		if (mainHeight < asideWrapperHeight) mainHeight = asideWrapperHeight;
		if (mainHeight < 300) mainHeight = 300;
		//console.log(mainHeight);
		$main.css("min-height", mainHeight);
		$main.css("height", mainHeight);
		mainHeight = $main.height();

		checkContactButton();

		if (!scrollTop  && $body.hasClass("sticky")) { $body.removeClass("sticky");  }



	}

	function checkContactButton() {
		var _mainHeight = $main.height();
		var $_headerLink = $(".header-link");
		var $_footerRightLink = $("footer").find(".right-link");
		$_headerLink.removeClass("hidden");
		//$_footerRightLink.hide();
		if (_mainHeight <= $w.height() - $("footer").height() - $("header").height()) {
			$_headerLink.addClass("hidden");

			//$_footerRightLink.show();
		}
		//console.log("checkContactButton");
	}

	function checkHeaderPosition() {
		//console.log("checkHeaderPosition");
		

		if (isMobile()) {
			$asideWrapper.css("margin-top", 0);
			return;
		}

		scrollTop = $w.scrollTop();
		var scrollTopYmin = 0;

		// console.log(scrollTop);
		// console.log("aa = " + distancewindowbody);

		//console.log("WindowHeight = " + $(window).height() + " / " + "bodyHeight = " + (parseInt($main.height())+200));
		//console.log((parseInt($main.height())+200) - $(window).height());
		if (((parseInt($main.height())+200) - $(window).height()) > 100) {
			if (scrollTop > scrollTopYmin && !$body.hasClass("sticky")) { $body.addClass("sticky"); }
			else if (scrollTop <= scrollTopYmin && $body.hasClass("sticky")) { $body.removeClass("sticky");  }
		} else $body.removeClass("sticky");

		if (isMobile()) {
			//console.log("remove sticky");
			$body.removeClass("sticky");
		}
	}


	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }
  function debounce_2(fn) {
    if(debounceTimeout_2) clearTimeout(debounceTimeout_2);
    debounceTimeout_2 = setTimeout(fn, debounceTime_2);
  }

 	return {
 		checkContactButton: checkContactButton
	};

})($(window));