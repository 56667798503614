var exhibitions = (function ($w) {
	var debounceTime = 130, debounceTimeout = null;
	var $exhibition = $(".exhibition");
	var $overview = $(".exhibitions").find(".overview");
	var $main = $("main");
	var mainHeight;
	var overviewHeight;

	if ($exhibition.length > 0) run();

	function run() {
	 attachEvents();
	}

	function attachEvents() {
		$w.resize(function () {
      debounce(checkHeightOnResize);
    });
    checkHeightOnResize();
    setTimeout(checkHeightOnResize, 100);
	}

	function isMobile() {
		if ($w.width() < 768) {
			return true;
		}	else {
			return false;
		}
	}

	function checkHeightOnResize() {
			mainHeight = $main.height();
			overviewHeight = $overview.height();
			if (!overviewHeight) overviewHeight = 0;
			else overviewHeight = overviewHeight + 17;
			//console.log(overviewHeight);
			var squareHeight = 0;

			//console.log(mainHeight);
			$.each( $exhibition, function( i, l ){
				if ($(this).hasClass("exhibition-1")) {
					$(this).height(mainHeight-overviewHeight);
					$(this).find(".exhibition-photo").height(mainHeight-overviewHeight-80);
					
				} else if ($(this).hasClass("exhibition-2")) {
					$(this).height(mainHeight-overviewHeight);
					if (isMobile()) {
						if ($(this).width()*2 < mainHeight) {
							$(this).height(mainHeight/2);
						} else {
							$(this).height($(this).width());
						}
					}
				} else {
					if (!squareHeight) squareHeight = $(this).width();
					$(this).height(squareHeight);
				}
			});

/*		if (isMobile()) {
			$menuLinks.css("max-height", ($w.height() - 110)+"px");
			$menuLinks.css("overflow-y", "auto");
		} else {
			$menuLinks.css("max-height", "10000px");
			$menuLinks.css("overflow", "hidden");
		}*/
		main.checkContactButton();
	}

	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }

  return {
		checkHeightOnResize: checkHeightOnResize
	};
 
})($(window)); 